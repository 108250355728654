import React from 'react';
import {
  Stack,
  Text,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
} from '@chakra-ui/core';
import Head from 'next/head';
import ContentContainer from '@bit/matternet.shared.components.content-container';

const ApplicationAccessRequired = () => {
  return (
    <>
      <Head>
        <title>Access Denied · Matternet Mission Control</title>
      </Head>
      <ContentContainer>
        <Stack spacing={8} shouldWrapChildren>
          <Text fontSize="3xl" fontWeight="bold">
            Access Denied
          </Text>

          <Alert status="error" rounded="lg" py={4}>
            <AlertIcon />
            <AlertTitle mr={4} textTransform="capitalize">
              Forbidden
            </AlertTitle>
            <AlertDescription>
              You do not have permission to access this application. Please
              contact your administrator
            </AlertDescription>
          </Alert>
        </Stack>
      </ContentContainer>
    </>
  );
};

export default ApplicationAccessRequired;
